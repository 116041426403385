import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"

const About = () => (
    <Layout>
        <div className="container">
            <h1>London Adult Education College</h1>
            <p>Completing your PCO Licence will be hassle free. We are the cheapest and one of London’s only all in one centre where you can complete your Topographical training, CRB
        check, medical and have your application correctly filled in by us, taking the headache out of the PCO application process. We offer applicants our expertise in forming a
        PCO application, passing the topographical test and sourcing jobs for you to become a fully licensed Uber or minicab driver as quickly as possible. Get in <Link to="/contact">contact</Link> today to
        join thousands of other new applicants on the road!
        </p>
        <br />
        </div>
    </Layout>
)

export default About
